<template>
  <section class="serie-details">
    <v-container>
      <template v-if="serie">
        <brand-serie-product-nav
          :brand="{ id: serie.brandId, name: serie.brandName }"
          :serie="serie"
        ></brand-serie-product-nav>
        <section class="my-8">
          <brand-serie-info :adminMode="adminMode" type="serie" :item="serie"></brand-serie-info>
        </section>
        <main class="mb-8">
          <template v-if="showProductLoader">
            <progress-bouncing center></progress-bouncing>
          </template>
          <template v-else-if="showErrorMessageProducts">
            <v-alert
              border="left"
              colored-border
              color="error"
              type="error"
              elevation="1"
            >
              En feil oppstod. Klarte ikke å laste produktene i serien.
            </v-alert>
          </template>
          <template v-else>
            <div class="d-flex align-center mb-8">
              <b
                >{{ products.length }} produkt{{
                  products.length === 1 ? "" : "er"
                }}</b
              >
              <v-spacer></v-spacer>
              <v-btn v-if="adminMode" color="primary" depressed rounded @click="addProduct()"
                ><span class="mr-1">Legg til produkt</span>
                <v-icon small>mdi-plus</v-icon></v-btn
              >
            </div>
            <v-row>
              <v-col
                cols="12"
                sm="6"
                md="4"
                lg="3"
                v-for="product in products"
                :key="product.id"
              >
                <product-card
                  :product="product"
                  :tag="product.tag"
                  tagPosition="right"
                  :to="{ name: 'Product', params: { id: product.id } }"
                  :imageOptions="{ minHeight: 240 }"
                  priceDescription="Veil. innpris"
                  height="100%"
                >
                </product-card>
              </v-col>
              <v-col v-if="adminMode" cols="12" sm="6" md="4" lg="3">
                <add-item-card
                  height="100%"
                  minHeight="200px"
                  hover
                  @click="addProduct()"
                >Legg til produkt</add-item-card>
              </v-col>
            </v-row>
          </template>
        </main>
      </template>
      <template v-else-if="showSerieLoader">
        <progress-bouncing class="my-5" center></progress-bouncing>
      </template>
      <template v-else-if="showErrorMessageSerie">
        <v-alert
          border="left"
          colored-border
          color="error"
          type="error"
          elevation="1"
        >
          En feil oppstod. Klarte ikke å laste serien.
        </v-alert>
      </template>
    </v-container>
  </section>
</template>

<script>
import ProductCard from "@/components/ProductCard.vue";
import ProgressBouncing from "@fixit/progress-bouncing";
import BrandSerieProductNav from "@/components/BrandSerieProductNav.vue";
import BrandSerieInfo from "@/views/brands/components/BrandSerieInfo.vue";
import AddItemCard from "@/views/brands/components/AddItemCard.vue";

export default {
  name: "SeriePage",
  components: {
    ProductCard,
    ProgressBouncing,
    BrandSerieProductNav,
    BrandSerieInfo,
    AddItemCard,
  },
  props: {
    adminMode: {
      type: Boolean,
      required: false,
      default: false,
    },
    id: {
      type: Number,
      required: true,
      default: 0,
    },
  },
  data() {
    return {
      loadingSerie: false,
      loadingProducts: false,
      errorSerie: false,
      errorProducts: false,
    };
  },
  computed: {
    serie() {
      return this.$store.getters["series/getSerieById"](this.id);
    },
    serieImage() {
      if (!this.serie) return "";
      return this.serie.imageUrl;
    },
    products() {
      return this.$store.getters["products/getProductsBySerieId"](this.id).map(
        toCardProduct
      ).sort((a, b) => (a.activeStatus > b.activeStatus) ? -1 : 1) //TODO: addSortUpcomingFromDate before activeStatus
    },
    showSerieLoader() {
      return this.products.length === 0 && this.loadingProducts;
    },
    showProductLoader() {
      return this.products.length === 0 && this.loadingProducts;
    },
    showErrorMessageSerie() {
      return !this.loadingSerie && this.errorSerie;
    },
    showErrorMessageProducts() {
      return !this.loadingProducts && this.errorProducts;
    },
  },
  methods: {
    async fetchSerie() {
      try {
        this.loadingSerie = true;
        await this.$store.dispatch("series/fetchSerie", this.id);
      } catch (err) {
        console.error(err);
        this.errorSerie = err;
      } finally {
        this.loadingSerie = false;
      }
    },
    async fetchProducts() {
      try {
        this.loadingProducts = true;
        await this.$store.dispatch("products/fetchProductsBySerieId", this.id);
      } catch (err) {
        console.error(err);
        this.errorProducts = err;
      } finally {
        this.loadingProducts = false;
      }
    },
    addProduct() {
      this.$router.push({
        name: "ProductCreate",
        query: {
          brandId: this.serie.brandId,
          serieId: this.serie.id,
        },
      });
    },
  },
  async created() {
    this.fetchSerie();
    this.fetchProducts();
  },
};

const toCardProduct = (p) => ({
  id: p.id,
  tag: getProductTag(p),
  brandName: p.brandName,
  serieName: p.productSeriesName,
  productName: p.productName,
  price: p.inPriceExVat,
  imageUrl: p.imageUrl,
  activeStatus: p.activeStatus,
  orderAvailableFrom: p.orderAvailableFrom,
  orderAvailableTo: p.orderAvailableTo 
});

const getProductTag = (p) => (p.isSalesProduct ? "S" : "F");
</script>

<style lang="scss" scoped>
.serie-details {
  text-align: left;
  color: var(--v-primary-base);
  .container {
    max-width: 1170px;
  }
}
</style>