<template>
  <v-row class="brand-serie-header">
    <v-col cols="12" sm="6" md="4">
      <brand-serie-image
        :image="image"
        :imageId="item.id"
        :type="type"
        :adminMode="adminMode"
        @image:uploaded="onImageUploaded()"
      ></brand-serie-image>
    </v-col>
    <v-col cols="12" sm="6" md="8">
      <div class="d-flex">
        <h2>{{ item.name }}</h2>
        <v-spacer></v-spacer>
        <v-btn v-if="adminMode" color="primary" depressed rounded outlined @click="edit()">
          <v-icon>mdi-pencil-outline</v-icon>
        </v-btn>
      </div>

      <div class="body">
        <p class="lh-1 mb-0">ID: {{ dataProviderItemId }}</p>
        <p class="lh-1">
          <span v-if="showSerieCount">
            {{ item.serieCount }} serie{{ item.serieCount === 1 ? "" : "r" }}
          </span>
          <template v-if="showSerieCount">|</template>
          <span>
            {{ item.productCount }} produkt{{
              item.productCount === 1 ? "" : "er"
            }}
          </span>
        </p>

        <template v-if="item.infoText">
          <p>{{ item.infoText }}</p>
        </template>
        <template v-else>
          <p class="text--disabled">Ingen beskrivelse</p>
        </template>
      </div>
    </v-col>
  </v-row>
</template>

<script>
import BrandSerieImage from "./BrandSerieImage.vue";

export default {
  name: "BrandSerieHeader",
  components: {
    BrandSerieImage,
  },
  props: {
    adminMode: {
      type: Boolean,
      required: false,
      default: false
    },
    type: {
      type: String,
      required: true,
      validator: (value) => ["brand", "serie"].includes(value),
    },
    item: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      brand: {
        dataProviderItemId: "dataProviderBrandId",
        onImageUploaded: () => {
          this.$store.dispatch("brands/fetchBrand", this.item.id);
        },
        onEdit: () => {
          this.$router.replace({ name: "BrandUpdate" });
        },
      },
      serie: {
        dataProviderItemId: "dataProviderProductSerieId",
        onImageUploaded: () => {
          this.$store.dispatch("series/fetchSerie", this.item.id);
        },
        onEdit: () => {
          this.$router.replace({ name: "SerieUpdate" });
        },
      },
    };
  },
  computed: {
    dataProviderItemId() {
      const dataProviderItemId = this[this.type]?.dataProviderItemId || "";
      return this.item[dataProviderItemId];
    },
    showSerieCount() {
      return this.item?.serieCount != null;
    },
    image() {
      return {
        src: this.item.imageUrl,
        alt: this.item.name,
      };
    },
  },
  methods: {
    onImageUploaded() {
      this[this.type].onImageUploaded();
    },
    edit() {
      this[this.type].onEdit();
    },
  },
};
</script>

<style scoped>
h2 {
  font-weight: bold;
  font-size: 30px;
  line-height: 0.67;
  margin-bottom: 20px;
}
.body {
  line-height: 1.25;
}
.lh-1 {
  line-height: 1;
}
</style>