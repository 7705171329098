<template>
  <section class="serie-create">
    <add-edit-serie
      v-model="addSerieDialog"
      :brandId="brandId"
      :loading="savingSerie"
      @confirm="createSerie($event)"
      @close="navigateToBrand()"
    ></add-edit-serie>
  </section>
</template>

<script>
import AddEditSerie from "@/views/series/components/AddEditSerie.vue";
import Serie from "@/models/Serie";
import notification from "@/utils/notification";

export default {
  name: "SerieCreate",
  components: {
    AddEditSerie,
  },
  props: {
    brandId: {
      type: Number,
    },
  },
  data() {
    return {
      addSerieDialog: true,
      savingSerie: false,
    };
  },
  methods: {
    async createSerie({
      dataProviderProductSerieId,
      dataProviderBrandId,
      name,
      description,
      image,
    }) {
      this.savingSerie = true;
      try {
        const serie = new Serie({
          id: 0,
          name,
          infoText: description,
          infoTitle: "",
          dataProviderBrandId,
          dataProviderProductSerieId,
        });
        const createdSerie = await this.$store.dispatch("series/createSerie", {
          serie,
          image,
        });
        if (createdSerie) {
          notification.primary("Serie opprettet");
          this.navigateToBrand(createdSerie.brandId);
        } else {
          notification.primary("Lagring av serie feilet");
        }
      } catch (error) {
        console.error(error);
        notification.primary("Lagring av serie feilet");
      } finally {
        this.savingSerie = false;
      }
    },
    navigateToBrand(brandId) {
      let id = brandId || this.brandId;
      let name = id ? "Brand" : "Brands";
      this.$router.replace({ name, params: { id } });
    },
  },
};
</script>

<style>
</style>