<template>
  <v-dialog v-model="show" no-click-animation :persistent="loading" fullscreen>
    <v-card tile>
      <v-toolbar class="toolbar" flat dense>
        <template v-if="loading">
          <v-toolbar-title class="primary--text"
            >Lagrer serie...</v-toolbar-title
          >
        </template>
        <template v-else>
          <v-btn icon @click="close()" :disabled="loading" color="primary"
            ><v-icon>mdi-chevron-left</v-icon></v-btn
          >
          <v-toolbar-title class="primary--text">{{
            toolbarTitle
          }}</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-card-actions>
            <v-btn
              class="white-btn"
              depressed
              rounded
              color="primary"
              outlined
              @click="close()"
              v-if="!loading"
            >
              <span class="mx-1">Avbryt</span>
              <v-icon small color="primary" class="mr-1">mdi-close</v-icon>
            </v-btn>

            <v-btn
              depressed
              rounded
              color="primary"
              @click="confirm()"
              :disabled="btnDisabled"
              :loading="loading"
            >
              <span class="mx-1">Lagre</span>
              <v-icon small color="white" class="mr-1">mdi-check</v-icon>
            </v-btn>
          </v-card-actions>
        </template>
      </v-toolbar>
      <v-container>
        <template v-if="loading">
          <div class="d-flex align-center justify-center mt-8">
            <progress-bouncing></progress-bouncing>
          </div>
        </template>
        <template v-else>
          <v-card-text class="pt-2">
            <v-row>
              <v-col cols="12" md="5">
                <brand-serie-image
                  :image="image"
                  :imageId="imageId"
                  type="serie"
                  editBtnVisible
                  @image:uploaded="onImageUploaded($event)"
                ></brand-serie-image>
              </v-col>

              <v-col cols="12" md="7">
                <v-row>
                  <template v-if="mode === 'create'">
                    <v-col cols="12">
                      <label>ID</label>
                      <v-text-field
                        filled
                        :background-color="powderBlueLight"
                        color="primary"
                        dense
                        counter
                        maxlength="128"
                        v-model="dataProviderProductSerieIdInput"
                        :disabled="mode !== 'create'"
                      ></v-text-field>
                    </v-col>
                  </template>
                  <v-col cols="12" sm="6">
                    <label>Merke</label>
                    <v-select
                      filled
                      :background-color="powderBlueLight"
                      color="primary"
                      dense
                      :disabled="loading"
                      :items="brands"
                      item-text="name"
                      item-value="id"
                      :loading="loadingBrands"
                      v-model="selectedBrandId"
                    ></v-select>
                  </v-col>
                  <v-col cols="12" sm="6">
                    <label>Serienavn</label>
                    <v-text-field
                      ref="firstInput"
                      filled
                      :background-color="powderBlueLight"
                      color="primary"
                      dense
                      counter
                      maxlength="128"
                      v-model.trim="textFieldInput"
                      :disabled="loading"
                      :rules="serieNameRules"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12">
                    <label>Beskrivelse</label>
                    <v-textarea
                      filled
                      :background-color="powderBlueLight"
                      color="primary"
                      dense
                      counter
                      maxlength="1024"
                      v-model.trim="textAreaInput"
                      :disabled="loading"
                    ></v-textarea>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-card-text>
        </template>
      </v-container>
    </v-card>
  </v-dialog>
</template>

<script>
import BrandSerieImage from "@/views/brands/components/BrandSerieImage.vue";

const mode = {
  UPDATE: "update",
  CREATE: "create",
};

export default {
  name: "AddEditSerie",
  components: {
    BrandSerieImage,
  },
  model: {
    prop: "dialog",
    event: "change",
  },
  props: {
    dialog: {
      type: Boolean,
      default: false,
    },
    item: {
      type: Object,
    },
    brandId: {
      required: false,
      default: undefined,
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      loadingBrands: false,
      powderBlueLight: "#DFF2F4",
      dataProviderProductSerieIdInput:
        this.item?.dataProviderProductSerieId || "",
      textFieldInput: this.item?.name || "",
      textAreaInput: this.item?.description || "",
      selectedBrandId: this.item?.brandId || this.brandId,
      base64image: null,
    };
  },
  computed: {
    toolbarTitle() {
      return this.mode === mode.CREATE ? "Opprett serie" : "Rediger serie";
    },
    mode() {
      return this.item != null && this.item.id ? mode.UPDATE : mode.CREATE;
    },
    image() {
      return {
        src: this.item?.imageUrl || this.base64image || null,
        alt: this.item?.name || "",
      };
    },
    imageId() {
      return this.item?.id || 0;
    },
    show: {
      get() {
        return this.dialog;
      },
      set(value) {
        this.$emit("change", value);
      },
    },
    hasChanged() {
      return (
        this.item?.brandId !== this.selectedBrandId ||
        this.item?.name !== this.textFieldInput ||
        this.item?.description !== this.textAreaInput
      );
    },
    btnDisabled() {
      return (
        !this.hasChanged ||
        !this.dataProviderProductSerieIdInput ||
        !this.selectedBrandId ||
        !this.textFieldInput
      );
    },
    brands() {
      return this.$store.getters["brands/getBrands"];
    },
    selectedBrand() {
      return this.$store.getters["brands/getBrandById"](this.selectedBrandId);
    },
    serieNameRules() {
      return [
        (value) => {
          return value ? true : "Serienavn er påkrevd";
        },
      ];
    },
  },
  watch: {
    show(isOpen) {
      if (isOpen) {
        this.dataProviderProductSerieIdInput =
          this.item?.dataProviderProductSerieId || "";
        this.selectedBrandId = this.item?.brandId || this.brandId;
        this.textFieldInput = this.item?.name || "";
        this.textAreaInput = this.item?.description || "";
        this.$nextTick(() => {
          const $input = this.$refs?.firstInput?.$refs?.input;
          $input && $input.focus();
        });
      }
    },
  },
  methods: {
    close() {
      if (!this.loading) {
        this.$emit("close");
      }
    },
    confirm() {
      if (!this.loading) {
        this.$emit("confirm", {
          dataProviderProductSerieId: this.dataProviderProductSerieIdInput,
          dataProviderBrandId: this.selectedBrand?.dataProviderBrandId || "",
          name: this.textFieldInput,
          description: this.textAreaInput,
          image: this.base64image,
        });
      }
    },
    onImageUploaded(response) {
      if (this.mode === mode.CREATE) {
        this.base64image = response.url;
      } else if (this.mode === mode.UPDATE) {
        this.updateSerieImage(response.url);
      }
    },
    updateSerieImage(imageUrl) {
      const currentSerie = this.$store.getters["series/getSerieById"](
        this.item.id
      );
      this.$store.commit("series/addOrUpdate", { ...currentSerie, imageUrl });
    },
  },
  async created() {
    if (!this.$store.state.brands.allFetched) {
      this.loadingBrands = true;
      await this.$store.dispatch("brands/fetchBrands");
      this.loadingBrands = false;
    }
  },
};
</script>

<style >
.brand-select .v-list-item__title {
  text-align: left !important;
}
label {
  display: block;
  font-weight: bold;
  color: var(--v-primary-base);
  text-align: left;
  font-size: 12px;
  line-height: 1.33;
}

.toolbar {
  background: linear-gradient(90deg, #bfe5e8, #efddd1);
}
.v-toolbar__title {
  font-weight: bold;
  font-size: 16px;
}
.white-btn {
  background: white;
}
</style>