<template>
  <section class="serie-update">
    <add-edit-serie
      v-if="serie"
      v-model="dialogOpen"
      :item="editItem"
      :loading="loading"
      @confirm="saveSerie($event)"
      @close="navigateToSerie()"
    ></add-edit-serie>
  </section>
</template>

<script>
import AddEditSerie from "@/views/series/components/AddEditSerie.vue";
import Serie from "@/models/Serie";
import notification from "@/utils/notification";

export default {
  name: "SerieUpdate",
  components: {
    AddEditSerie,
  },
  props: {
    id: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      dialogOpen: true,
      loading: false,
    };
  },
  computed: {
    adminMode(){ return (this.$store.state.authorization.userRole == 'Supplier')},
    serie() {
      return this.$store.getters["series/getSerieById"](this.id);
    },
    editItem() {
      if (!this.serie) return null;
      return {
        ...this.serie,
        description: this.serie.infoText,
      };
    },
  },
  methods: {
    async fetchSerie() {
      if (!this.serie) {
        try {
          this.loading = true;
          await this.$store.dispatch("series/fetchSerie", this.id);
        } catch (err) {
          console.error(err);
        } finally {
          this.loading = false;
        }
      }
    },
    async saveSerie(editedItem) {
      try {
        let result = null;
        this.loading = true;

        const serie = new Serie({
          ...this.serie,
          dataProviderBrandId: editedItem.dataProviderBrandId,
          name: editedItem.name,
          infoTitle: "",
          infoText: editedItem.description,
        });

        result = await this.$store.dispatch("series/updateSerie", serie);

        if (result) {
          notification.primary("Endringen av serie ble lagret");
          this.navigateToSerie();
        } else {
          notification.error("Lagring av serie feilet");
        }
      } catch (err) {
        notification.error("Lagring av serie feilet");
        console.error(err);
      } finally {
        this.loading = false;
      }
    },
    navigateToSerie() {
      this.$router.replace({ name: "Serie", params: { id: this.id } });
    },
  },
  created() {
    this.fetchSerie();
  },
};
</script>
