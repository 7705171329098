<template>
  <image-with-image-uploader
    v-if="adminMode"
    :adminMode="adminMode"
    :image="image"
    :imageType="imageType"
    :imageId="imageId"
    :aspectRatio="aspectRatio"
    :editBtnVisible="editBtnVisible"
    @image:uploaded="onImageUploaded($event)"
    :minWidth="minWidth"
    :minHeight="minHeight"
  ></image-with-image-uploader>
  <v-img v-else :src="image"></v-img>
</template>

<script>
import { SupplierBrand, SupplierSerie } from "@/models/enums/ImageTypes";
import ImageWithImageUploader from "@/components/ImageWithImageUploader.vue";

const IMAGE_MIN_WIDTH = 640;
const IMAGE_MIN_HEIGHT = 340;

export default {
  name: "BrandSerieImage",
  components: {
    ImageWithImageUploader,
  },
  props: {
    adminMode: {
      type: Boolean,
      required: false,
      default: false
    },
    image: {
      type: Object,
      required: true,
    },
    imageId: {
      type: [String, Number],
      required: true,
    },
    type: {
      type: String,
      required: true,
      validator: (value) => ["brand", "serie"].includes(value),
    },
    editBtnVisible: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      imageUploadDialog: false,
      minWidth: IMAGE_MIN_WIDTH,
      minHeight: IMAGE_MIN_HEIGHT,
      aspectRatio: IMAGE_MIN_WIDTH / IMAGE_MIN_HEIGHT,
      imageTypes: {
        brand: SupplierBrand,
        serie: SupplierSerie,
      },
    };
  },
  computed: {
    imageType() {
      return this.imageTypes[this.type];
    },
  },
  methods: {
    onImageUploaded(response) {
      this.$emit("image:uploaded", response);
    },
  },
};
</script>

<style>
</style>